<template>
  <!-- 课程配置 -->
  <div>
    <!-- 发起收集任务 -->
    <el-row style="margin-bottom: 20px">
      <el-button
        @click="handleAddCourse"
        type="primary"
        style="height: 40px; margin-right: 20px"
        >添加课程配置</el-button
      >
    </el-row>
    <!-- 条件 -->
    <el-row style="display: flex; margin-bottom: -20px">
      <el-form :inline="true" ref="conditionForm" :model="conditionForm">
        <el-form-item
          label="课程名称/编号"
          class="formItemBoxStyle"
          prop="nameOrCode"
        >
          <el-input
            v-model="conditionForm.nameOrCode"
            @input="inquireBtn"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="科目"
          class="formItemBoxStyle"
          prop="sysSubjectDicId"
        >
          <el-select
            v-model="conditionForm.sysSubjectDicId"
            @change="inquireBtn"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in subjectList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="学段"
          class="formItemBoxStyle"
          prop="sysPeriodDicId"
        >
          <el-select
            v-model="conditionForm.sysPeriodDicId"
            @change="inquirePeriodBtn"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in periodList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="年级"
          class="formItemBoxStyle"
          prop="sysSchoolClassGradeId"
        >
          <el-select
            v-model="conditionForm.sysSchoolClassGradeId"
            @change="inquireBtn"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in allGradeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="状态" class="formItemBoxStyle" prop="enable">
          <el-select v-model="conditionForm.enable" @change="inquireBtn">
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button @click="resetBtn">重置</el-button>
          <el-button type="primary" @change="inquireBtn">查询</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 表格 -->
    <el-row style="margin-top: 20px">
      <el-table
        ref="tableData"
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
        border
      >
        <el-table-column
          label="课程编号"
          prop="code"
          width="200"
        ></el-table-column>
        <el-table-column label="课程名称" prop="name">
          <template slot-scope="scope">
            <span
              @click="handleEditeCourseBtn(scope.row, 2)"
              style="color: #00f; cursor: pointer; text-decoration: underline"
              >{{ scope.row.name }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="科目" width="120">
          <template slot-scope="scope">
            {{ scope.row.sysSubjectDic.sysDicName }}
          </template>
        </el-table-column>
        <el-table-column label="关联实验" prop="sysExperimentId">
          <template slot-scope="scope">
            {{ findExpNameFunc(scope.row.sysExperimentId) }}
          </template>
        </el-table-column>
        <el-table-column label="学段" prop="sysPeriodDicId" width="120">
          <template slot-scope="scope">
            {{ scope.row.schoolSectionDic.sysDicName }}
          </template>
        </el-table-column>
        <el-table-column
          label="年级"
          prop="sysSchoolClassGradeName"
          width="120"
        ></el-table-column>
        <!-- <el-table-column label="状态" width="70">
          <template slot-scope="scope">
            {{ scope.row.enable === 1 ? "启用" : "停用" }}
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              @click="handleEditeCourseBtn(scope.row, 0)"
              size="mini"
              type="text"
              style="color: #67c23a"
              >编辑</el-button
            >
            <!-- <el-button
              v-if="scope.row.enable === 1"
              @click="dialogVisibleBtn('停用', scope.row.crsExpCourseId)"
              size="mini"
              type="text"
              >停用</el-button
            >
            <el-button
              v-else
              @click="dialogVisibleBtn('启用', scope.row.crsExpCourseId)"
              size="mini"
              type="text"
              >启用</el-button
            > -->
            <el-button
              @click="dialogVisibleBtn('删除', scope.row.crsExpCourseId)"
              size="mini"
              type="text"
              style="color: red"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>

    <!-- 课程弹框 -->
    <el-dialog
      :visible.sync="courseDialogVisible"
      :title="addOrEdit ? '添加课程' : '编辑课程'"
      top="4%"
      width="800px"
    >
      <el-form
        style="margin-left: 20px"
        :model="courseForm"
        ref="courseForm"
        :rules="rules"
      >
        <el-form-item label="所属科目:" prop="sysSubjectDicId">
          <el-select
            style="width: 60%"
            v-model="courseForm.sysSubjectDicId"
            placeholder="请选择"
            :disabled="addOrEdit === 2"
            @change="handleSubjectSelect"
          >
            <el-option
              v-for="item in subjectList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联实验:" prop="sysExperimentName">
          <el-input
            style="width: 60%"
            placeholder="请选择"
            disabled
            v-model="courseForm.sysExperimentName"
          />
          <div
            v-if="addOrEdit !== 2"
            class="selectText"
            @click="handleSelectExpBtn"
          >
            选择
          </div>
        </el-form-item>
        <el-form-item label="课程名称:" prop="name">
          <el-input
            style="width: 60%"
            v-model="courseForm.name"
            placeholder="请输入"
            :disabled="addOrEdit === 2"
          />
        </el-form-item>
        <el-form-item label="课程编号:" prop="code">
          <el-input
            style="width: 60%"
            v-model="courseForm.code"
            placeholder="请输入"
            :disabled="addOrEdit === 2"
          />
        </el-form-item>
        <el-form-item
          label="学段:"
          prop="sysPeriodDicId"
          style="margin-left: 28px"
        >
          <el-select
            style="width: 62%"
            v-model="courseForm.sysPeriodDicId"
            placeholder="请选择"
            :disabled="addOrEdit === 2"
            @change="handleChangePeriod"
          >
            <el-option
              v-for="item in periodList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="年级:"
          prop="sysSchoolClassGradeId"
          style="margin-left: 28px"
        >
          <el-select
            style="width: 62%"
            v-model="courseForm.sysSchoolClassGradeId"
            placeholder="请选择"
            :disabled="addOrEdit === 2"
          >
            <el-option
              v-for="item in gradeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="演示实验:" prop="demoExpStatus">
          <el-radio
            style="margin-left: 10px"
            v-model="courseForm.demoExpStatus"
            :disabled="addOrEdit === 2"
            :label="1"
            >必做</el-radio
          >
          <el-radio
            v-model="courseForm.demoExpStatus"
            :label="2"
            :disabled="addOrEdit === 2"
            >选做</el-radio
          >
        </el-form-item>
        <el-form-item label="分组实验:" prop="groupExpStatus">
          <el-radio
            style="margin-left: 10px"
            v-model="courseForm.groupExpStatus"
            :label="1"
            :disabled="addOrEdit === 2"
            >必做</el-radio
          >
          <el-radio
            :disabled="addOrEdit === 2"
            v-model="courseForm.groupExpStatus"
            :label="2"
            >选做</el-radio
          >
        </el-form-item>
        <el-form-item label="备注:" prop="mark" style="margin-left: 42px">
          <el-input
            type="textarea"
            :rows="3"
            resize="none"
            style="width: 62%"
            :disabled="addOrEdit === 2"
            v-model="courseForm.mark"
          />
        </el-form-item>
      </el-form>
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin: 20px 0 10px;
          font-size: 16px;
        "
      >
        <div><span style="color: red">*演示</span>实验仪器耗材:</div>
        <div
          style="text-decoration: underline; color: #00f; cursor: pointer"
          @click="handleAddMaterialBtn(1)"
          v-if="addOrEdit !== 2"
        >
          +添加
        </div>
      </div>
      <el-table
        :data="demoExpTable"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
      >
        <el-table-column label="编号" prop="internationCode"></el-table-column>
        <el-table-column label="名称" prop="materialName"></el-table-column>
        <el-table-column
          label="规格"
          prop="materialSpecifications"
        ></el-table-column>
        <el-table-column label="数量" prop="stockNum">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.stockNum"
              type="number"
              :disabled="addOrEdit === 2"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作" v-if="addOrEdit !== 2">
          <template slot-scope="scope">
            <span
              style="color: red; cursor: pointer"
              @click="handleDeleteDemoItem(scope.$index)"
              >删除</span
            >
          </template>
        </el-table-column>
      </el-table>
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin: 20px 0 10px;
          font-size: 16px;
        "
      >
        <div>
          <span style="color: red">*分组</span>实验仪器耗材(每组所需实验装备):
        </div>
        <div
          style="text-decoration: underline; color: #00f; cursor: pointer"
          @click="handleAddMaterialBtn(2)"
          v-if="addOrEdit !== 2"
        >
          +添加
        </div>
      </div>
      <el-table
        :data="groupExpTable"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
      >
        <el-table-column label="编号" prop="internationCode"></el-table-column>
        <el-table-column label="名称" prop="materialName"></el-table-column>
        <el-table-column
          label="规格"
          prop="materialSpecifications"
        ></el-table-column>
        <el-table-column label="数量" prop="stockNum">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.stockNum"
              type="number"
              :disabled="addOrEdit === 2"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作" v-if="addOrEdit !== 2">
          <template slot-scope="scope">
            <span
              style="color: red; cursor: pointer"
              @click="handleDeleteGroupItem(scope.$index)"
              >删除</span
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px; text-align: right" v-if="addOrEdit !== 2">
        <el-button type="primary" @click="handleConfirmCourseBtn"
          >确认</el-button
        >
        <el-button
          @click="
            () => {
              courseDialogVisible = false
            }
          "
          >取消</el-button
        >
      </div>
      <div style="margin-top: 20px; text-align: right" v-else>
        <el-button
          @click="
            () => {
              courseDialogVisible = false
            }
          "
          >关闭</el-button
        >
      </div>
    </el-dialog>

    <!-- 点击选择关联实验弹框 -->
    <el-dialog
      width="1100px"
      title="选择关联实验"
      :visible.sync="addExpDialogVisible"
    >
      <el-form inline :model="addExpFormCondition">
        <el-form-item label="实验分类">
          <el-select
            placeholder="请选择"
            v-model="addExpFormCondition.categoryId"
            @change="handleQueryAddExpTable"
          >
            <el-option
              v-for="item in expCategoryList"
              :key="item.categoryId"
              :label="item.categoryName"
              :value="item.categoryId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="实验名称" prop="experimentCodeName">
          <el-input
            placeholder="请输入"
            v-model="addExpFormCondition.experimentName"
            @input="handleQueryAddExpTable"
          ></el-input>
        </el-form-item>
        <el-form-item label="实验编号" prop="experimentCodeName">
          <el-input
            placeholder="请输入"
            v-model="addExpFormCondition.experimentCode"
            @input="handleQueryAddExpTable"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleResetAddExpTable">重置</el-button>
          <el-button @click="handleQueryAddExpTable" type="primary"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-row>
        <div style="position: relative">
          <el-table
            border
            :data="experimentDataList"
            ref="experimentDataList"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
            @row-click="handleRowClickToAddFunctionRoom"
            @select="handleFunctionRoomSelectionChange"
          >
            <el-table-column
              prop="categoryName"
              label="实验分类"
              center
              :resizable="false"
            ></el-table-column>
            <el-table-column
              :resizable="false"
              prop="experimentName"
              label="实验名称"
              center
            >
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="experimentCode"
              label="实验编号"
              center
            >
            </el-table-column>
            <el-table-column
              :resizable="false"
              center
              width="50"
              type="selection"
            >
            </el-table-column>
          </el-table>
          <div
            style="
              width: 40px;
              height: 40px;
              position: absolute;
              top: 0;
              right: 0;
              background-color: #dfe6ec;
              z-index: 999;
            "
          ></div>
        </div>
      </el-row>
      <!-- 分页组件 -->
      <el-row style="margin-top: 20px">
        <el-pagination
          @size-change="handleSizeChangeaddExpTableMsg"
          @current-change="handleCurrentChangeaddExpTableMsg"
          :current-page="addExpTableMsg.pageIndex"
          :page-sizes="[5, 10, 20, 50, 100]"
          :page-size="addExpTableMsg.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="addExpTableMsg.totalSize"
        ></el-pagination>
      </el-row>
      <div style="margin-top: 20px; text-align: right">
        <el-button @click="handleCancelAddFunctionRoom">取消</el-button>
        <el-button type="primary" @click="handleConfirmAddFunctionRoom"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!-- 添加仪器耗材弹框 -->
    <el-dialog
      :visible.sync="addMaterialDialogVisible"
      title="添加仪器耗材"
      width="900px"
      top="3%"
    >
      <template>
        <div style="display: flex; justify-content: space-between">
          <div class="tag-title">已选择</div>
          <span
            @click="handleRemoveAllAddedMaterialBtnClick"
            style="
              color: red;
              text-decoration: underline;
              cursor: pointer;
              margin-right: 10px;
            "
            >清空所有</span
          >
        </div>

        <div class="tag-content">
          <div
            v-for="item in addMaterialList"
            :key="item.materialId"
            class="tag-content-item"
          >
            {{ item.materialName }}
            <div
              class="tag-item-x"
              @click="handleRemoveSelectMaterialBtn(item)"
            >
              X
            </div>
          </div>
        </div>
        <div class="tag-title">仪器耗材库</div>
        <el-row>
          <el-input
            v-model="materialCodeName"
            placeholder="请输入编号或名称进行查询"
            style="width: 300px; margin-right: 20px"
            @input="handleQueryMaterialTableDataBtnClick"
          />
          <el-button @click="handleResetMaterialTableDataBtnClick"
            >重置</el-button
          >
          <el-button
            type="primary"
            @click="handleQueryMaterialTableDataBtnClick"
            >查询</el-button
          >
          <!-- <el-button
            type="primary"
            style="margin-left: 280px"
            v-throttle
            @click="handleBatchAddBtnClick"
            >批量添加</el-button
          > -->
        </el-row>
        <div style="margin-top: 20px">
          <el-table
            border
            :data="materialTableDataList"
            ref="materialTableDataList"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
            @select="handleSelectOneMaterial"
            @select-all="handleSelectAllMaterial"
            @row-click="materialRowClick"
          >
            <el-table-column
              prop="internationCode"
              label="国标编号"
              center
              width="80px"
            ></el-table-column>
            <el-table-column prop="materialName" label="名称" center>
            </el-table-column>
            <el-table-column
              prop="materialSpecifications"
              label="规格型号功能"
              center
              width="150px"
            ></el-table-column>
            <el-table-column
              prop="materialUnit"
              label="单位"
              center
              width="60px"
            ></el-table-column>
            <el-table-column label="标准数量" center>
              <template slot-scope="scoped">
                {{ scoped.row.materialMinStandardNum }} ~
                {{ scoped.row.materialMaxStandardNum }}
              </template>
            </el-table-column>
            <el-table-column prop="materialHasDanger" label="是否危化品" center>
              <template slot-scope="scoped">
                {{ scoped.row.materialHasDanger ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="materialHasConsumables"
              label="是否消耗品"
              center
            >
              <template slot-scope="scoped">
                {{ scoped.row.materialHasConsumables ? '是' : '否' }}
              </template>
            </el-table-column>
            <!-- <el-table-column label="操作">
              <template slot-scope="scoped">
                <div
                  v-if="!handleMaterialStatusCheck(scoped.row)"
                  style="
                    color: #00f;
                    text-decoration: underline;
                    cursor: pointer;
                  "
                  @click="handleTableAddMaterialBtnClick(scoped.row)"
                >
                  添加
                </div>
                <div v-else style="color: #ccc">已添加</div>
              </template>
            </el-table-column> -->
            <el-table-column type="selection" width="55"> </el-table-column>
          </el-table>
        </div>
        <el-row style="margin-top: 20px">
          <el-pagination
            @size-change="handleSizeChangeMaterialTableDataMsgPage"
            @current-change="handleCurrentChangeMaterialTableDataMsgPage"
            :current-page="materialTableDataMsgPage.pageIndex"
            :page-sizes="[5, 10, 20, 50, 100, 150]"
            :page-size="materialTableDataMsgPage.pageSize"
            layout="->,total,  prev, pager, next,sizes, jumper"
            :total="materialTableDataMsgPage.pageTotal"
          ></el-pagination>
        </el-row>
        <div style="margin-top: 20px; text-align: right">
          <el-button @click="handleCancelAddMaterialBtnClick">取消</el-button>
          <el-button type="primary" @click="handleSaveAddMaterialBtnClick"
            >保存</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  addExpCourseApi,
  delExpCourseApi,
  getSubjectExpListApi,
  getMaterialStandardListApi,
  getExpCourseListApi,
  updateExpCourseApi,
} from '@/api/courseStart/courseConfiguration.js'
import { getMaterialListApi } from '@/api/standard/standardDeviceLib.js'
import {
  getMaterialMenuInfoApi,
  getLabInfoDetailApi,
  getLabInfoFormMsgApi,
} from '@/api/standard/menuManagement.js'
export default {
  name: 'courseConfiguration',
  data() {
    return {
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      tableData: [],
      conditionForm: {
        nameOrCode: '',
        sysPeriodDicId: '',
        sysSubjectDicId: '',
        sysSchoolClassGradeId: '',
        enable: '',
      },

      // 课程弹框
      addOrEdit: 1, //  0 编辑 1 新增 2 查看
      courseDialogVisible: false,
      courseForm: {
        sysSubjectDicId: '',
        sysExperimentName: '',
        name: '',
        code: '',
        sysPeriodDicId: '',
        demoExpStatus: 1,
        groupExpStatus: 1,
        sysSchoolClassGradeId: '',
      },
      rules: {
        sysSubjectDicId: [
          { required: true, message: '请选择科目', trigger: 'change' },
        ],
        sysExperimentName: [
          { required: true, message: '请选择实验', trigger: 'change' },
        ],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入编号', trigger: 'blur' }],
        sysPeriodDicId: [
          { required: true, message: '请选择学段', trigger: 'change' },
        ],
        sysSchoolClassGradeId: [
          { required: true, message: '请选择年级', trigger: 'change' },
        ],
        demoExpStatus: [
          { required: true, message: '请选择演示实验', trigger: 'change' },
        ],
        groupExpStatus: [
          { required: true, message: '请选择分组实验', trigger: 'change' },
        ],
      },

      demoExpTable: [], // 演示耗材
      groupExpTable: [], // 分组实验耗材

      subjectList: [], // 科目
      periodList: [], // 学段

      // 选择关联实验
      addExpDialogVisible: false,
      addExpFormCondition: {
        categoryId: '', // 实验分类ID
        experimentCode: '', // 实验编号
        // experimentDescription: "", // 实验备注
        // experimentId: "", // 主键ID
        experimentName: '', // 名称
        // experimentPrinciple: "", // 实验原理
        // experimentPurpose: "", // 实验目的
        // experimentStep: "", // 实验步骤
        // tenantId: "",
      },
      experimentDataList: [],
      addExpTableMsg: {
        pageIndex: 1,
        pageSize: 5,
        totalSize: 0,
      },
      expSelectedRow: '',
      sysExperimentId: '',
      sysExperimentId: '',

      // 添加仪器弹框
      addMaterialDialogVisible: false,
      materialTableDataList: [],
      materialTableDataMsgPage: {
        pageIndex: 1,
        pageSize: 5,
        pageTotal: 0,
      },
      addedMaterialDataList: [],
      addMaterialList: [],
      materialCodeName: '',

      addDemoOrGroup: 1, // 1 演示 0 分组
      crsExpCourseId: '',

      expCategoryList: [],

      allGradeList: [],

      expList: [],
    }
  },
  computed: {
    gradeList() {
      let result = []
      this.periodList.forEach((item) => {
        if (item.value === this.courseForm.sysPeriodDicId) {
          result = item.children
        }
      })

      return result
    },
  },
  async created() {
    const { dataArr } = this.$store.state.public_data
    await Promise.all(dataArr.map((e) => this.$store.dispatch('loadData', e)))

    // console.log(this.$store.state.public_data);
    // 科目
    this.subjectList = this.$store.state.public_data.sysSubjectDicList
    // 学段
    this.periodList = this.$store.state.public_data.sysSemesterTermGradeDicList

    this.fetchCourseDataList()
    this.fetchAllExpList()
  },
  mounted() {},
  methods: {
    // 获取课程列表
    fetchCourseDataList() {
      getExpCourseListApi(
        this.page.pageIndex,
        this.page.pageSize,
        this.conditionForm
      ).then((res) => {
        if (res.success) {
          this.tableData = res.data
          this.page.totalSize = res.total
        } else {
          this.$message.error(res.msg)
          return
        }
      })
    },
    // 获取耗材数据
    fetchMaterialListInfo(materialCodeName = this.materialCodeName) {
      getMaterialListApi(
        this.materialTableDataMsgPage.pageIndex,
        this.materialTableDataMsgPage.pageSize,
        {
          materialCodeName,
        }
      ).then((res) => {
        if (res.success) {
          this.materialTableDataList = res.data
          if (
            this.addMaterialList.length > 0 &&
            this.materialTableDataList.length > 0
          ) {
            this.materialTableDataList.forEach((it) => {
              this.addMaterialList.forEach((it2) => {
                if (it.materialId == it2.materialId) {
                  this.$nextTick(() => {
                    this.$refs.materialTableDataList.toggleRowSelection(
                      it,
                      true
                    );
                    it.isSelected = true;
                  });
                }
              });
            });
          }
          this.materialTableDataMsgPage.pageTotal = res.total
        } else {
          this.$message.error(res.msg)
          return
        }
      })
    },
    fetchAllExpList() {
      let params = {
        categoryId: 0,
        experimentCode: '',
        experimentName: '',
        subjectId: 0,
      }
      getLabInfoFormMsgApi(1, 10000, params).then((res) => {
        // console.log(res,"res")
        this.expList = res.data
      })
    },
    // 获取实验
    fetchExpList() {
      getSubjectExpListApi(
        this.addExpTableMsg.pageIndex,
        this.addExpTableMsg.pageSize,
        this.courseForm.sysSubjectDicId,
        this.addExpFormCondition
      ).then((res) => {
        if (res.success) {
          this.addExpDialogVisible = true
          this.experimentDataList = res.data
          this.addExpTableMsg.totalSize = res.total
        } else {
          this.$message.error(res.msg)
          return
        }
      })
    },
    // 获取某科目下的实验分类
    fetchExpCategory() {
      let params = {
        categoryCode: '',
        categoryId: 0,
        categoryName: '',
        subjectId: 0,
        tenantId: 0,
      }
      getMaterialMenuInfoApi(params).then((res) => {
        if (res.success) {
          if (res.data.length > 0 && !!this.courseForm.sysSubjectDicId) {
            res.data.forEach((it) => {
              if (it.sysDicDTO.sysDicId === this.courseForm.sysSubjectDicId) {
                this.expCategoryList = it.experimentCategoryDTOList
              }
            })
          }
        } else {
          this.$message.error(res.msg)
          return
        }
      })
    },
    // 添加课程配置
    handleAddCourse() {
      this.addOrEdit = 1
      this.demoExpTable = []
      this.groupExpTable = []
      this.courseForm = {
        sysSubjectDicId: '',
        sysExperimentName: '',
        name: '',
        code: '',
        sysPeriodDicId: '',
        demoExpStatus: 1,
        groupExpStatus: 1,
        sysSchoolClassGradeId: '',
      }
      this.courseDialogVisible = true
      this.$nextTick(() => {
        this.$refs.courseForm.clearValidate()
      })
    },
    // 编辑课程配置
    handleEditeCourseBtn(row, val) {
      this.addOrEdit = val
      this.courseForm = {
        sysSubjectDicId: row.sysSubjectDic.sysDicId,
        sysExperimentName: row.crsExpCourseId,
        name: row.name,
        code: row.code,
        sysPeriodDicId: row.schoolSectionDic.sysDicId,
        demoExpStatus: row.demoExpStatus,
        groupExpStatus: row.groupExpStatus,
        sysSchoolClassGradeId: row.sysSchoolClassGradeId,
        mark: row.mark,
      }
      // 演示仪器耗材
      this.demoExpTable = row.crsExpCourseConsumeMaterialList
        .filter((it) => it.experimentType === 1)
        .map((it) => ({
          ...it,
          materialName: it.equipmentName,
          materialSpecifications: it.equipmentSpecifications,
          stockNum: it.equipmentNum,
        }))
      this.crsExpCourseId = row.crsExpCourseId
      this.sysExperimentId = row.sysExperimentId
      // 分组仪器耗材
      this.groupExpTable = row.crsExpCourseConsumeMaterialList
        .filter((it) => it.experimentType === 2)
        .map((it) => ({
          ...it,
          materialName: it.equipmentName,
          materialSpecifications: it.equipmentSpecifications,
          stockNum: it.equipmentNum,
        }))
      getLabInfoDetailApi(row.sysExperimentId).then((res) => {
        if (res.success) {
          this.courseForm.sysExperimentName = res.data.experimentName
          this.courseDialogVisible = true
        } else {
          this.$message.error(res.msg)
          return
        }
      })
    },
    // 确认课程配置
    handleConfirmCourseBtn() {
      this.$refs.courseForm.validate((valid) => {
        if (valid) {
          if (
            this.courseForm.demoExpStatus === 1 &&
            this.demoExpTable.length === 0
          ) {
            this.$message.error('请添加演示实验仪器耗材')
            return
          }
          if (
            this.courseForm.groupExpStatus === 1 &&
            this.groupExpTable.length === 0
          ) {
            this.$message.error('请添加分组实验仪器耗材')
            return
          }
          let params = {
            code: this.courseForm.code,
            demoCrsExpCourseConsumeMaterialParamList: this.demoExpTable.map(
              (item) => ({
                equipmentNum: item.stockNum,
                sysExperimentConsumeMaterialId:
                  item.sysExperimentConsumeMaterialId
                    ? item.sysExperimentConsumeMaterialId
                    : item.materialId,
                crsExpCourseConsumeMaterialId:
                  item.crsExpCourseConsumeMaterialId
                    ? item.crsExpCourseConsumeMaterialId
                    : null,
              })
            ),
            demoExpStatus: this.courseForm.demoExpStatus,
            groupCrsExpCourseConsumeMaterialParamList: this.groupExpTable.map(
              (item) => ({
                equipmentNum: item.stockNum,
                sysExperimentConsumeMaterialId:
                  item.sysExperimentConsumeMaterialId
                    ? item.sysExperimentConsumeMaterialId
                    : item.materialId,
                crsExpCourseConsumeMaterialId:
                  item.crsExpCourseConsumeMaterialId
                    ? item.crsExpCourseConsumeMaterialId
                    : null,
              })
            ),
            groupExpStatus: this.courseForm.groupExpStatus,
            mark: this.courseForm.mark,
            name: this.courseForm.name,
            sysExperimentId: this.sysExperimentId,
            sysSchoolClassGradeId: this.courseForm.sysSchoolClassGradeId,
            sysSubjectDicId: this.courseForm.sysSubjectDicId,
          }

          // 进行添加或编辑操作
          if (this.addOrEdit === 1) {
            addExpCourseApi(params).then((res) => {
              if (res.success) {
                this.$message.success('添加成功')
                this.fetchCourseDataList()
                this.courseDialogVisible = false
              } else {
                this.$message.error(res.msg)
                return
              }
            })
          } else if (this.addOrEdit === 0) {
            let data = {
              ...params,
              crsExpCourseId: [this.crsExpCourseId],
              sysExperimentId: this.sysExperimentId,
            }
            updateExpCourseApi(this.crsExpCourseId, data).then((res) => {
              if (res.success) {
                this.$message.success('编辑成功')
                this.fetchCourseDataList()
                this.courseDialogVisible = false
              } else {
                this.$message.error(res.msg)
                return
              }
            })
          }
        }
      })
    },
    // 课程配置状态更改
    dialogVisibleBtn(keyword, id) {
      this.$confirm(`确定${keyword}该课程吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (keyword === '删除') {
            // 进行删除操作
            delExpCourseApi(id).then((res) => {
              if (res.success) {
                this.fetchCourseDataList()
                this.$message({
                  type: 'success',
                  message: `${keyword}成功!`,
                })
              } else {
                this.$message.error(res.msg)
                return
              }
            })
          } else {
            let crsExpCourseId = id
            let params = {
              crsExpCourseId: id,
              enable: keyword === '启用' ? 1 : 2,
            }
            // 进行状态修改操作
            updateExpCourseApi(crsExpCourseId, params).then((res) => {
              if (res.success) {
                this.$message({
                  type: 'success',
                  message: `${keyword}成功!`,
                })
                this.fetchCourseDataList()
              } else {
                this.$message.error(res.msg)
                return
              }
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `已取消${keyword}`,
          })
        })
    },

    // 添加关联实验
    handleSelectExpBtn() {
      if (!this.courseForm.sysSubjectDicId) {
        this.$message.error('请选择科目')
        return
      }
      this.fetchExpList()
      this.fetchExpCategory()
      this.addExpDialogVisible = true
    },
    // 关联实验表格行点击
    handleRowClickToAddFunctionRoom(row, column, event) {
      this.handleFunctionRoomSelectionChange(_, row)
    },
    // 关联实验选择事件
    handleFunctionRoomSelectionChange(rows, row) {
      // 清空所有选择
      this.$refs.experimentDataList.clearSelection()
      // 再根据selected的值进行目标选框状态确定
      this.experimentDataList.forEach((it) => {
        if (it.experimentId == row.experimentId) {
          // console.log(row.selected);
          if (row.selected) {
            it.selected = false
            this.$refs.experimentDataList.toggleRowSelection(row, false)
          } else {
            it.selected = true
            this.expSelectedRow = row
            this.$refs.experimentDataList.toggleRowSelection(row, true)
          }
        } else {
          it.selected = false
        }
      })
    },
    // 查询关联实验
    handleQueryAddExpTable() {
      this.addExpTableMsg.pageIndex = 1
      this.addExpTableMsg.pageSize = 5
      this.fetchExpList()
    },
    // 重置关联实验表格
    handleResetAddExpTable() {
      this.addExpFormCondition = {
        categoryId: '', // 实验分类ID
        experimentCode: '', // 实验编号
        // experimentDescription: "", // 实验备注
        // experimentId: "", // 主键ID
        experimentName: '', // 名称
        // experimentPrinciple: "", // 实验原理
        // experimentPurpose: "", // 实验目的
        // experimentStep: "", // 实验步骤
        // tenantId: "",
      }
      this.handleQueryAddExpTable()
    },
    // 关联实验表格切换当前行
    handleCurrentChangeaddExpTableMsg(val) {
      this.addExpTableMsg.pageIndex = val
      this.addExpTableMsg.pageSize = 5
      this.fetchExpList()
    },
    // 关联实验表格切换页容量
    handleSizeChangeaddExpTableMsg(val) {
      this.addExpTableMsg.pageSize = val
      this.fetchExpList()
    },
    // 取消选择关联实验
    handleCancelAddFunctionRoom() {
      this.addExpDialogVisible = false
    },
    // 确认选择关联实验
    handleConfirmAddFunctionRoom() {
      this.courseForm.sysExperimentName = this.expSelectedRow.experimentName
      this.sysExperimentId = this.expSelectedRow.experimentId
      this.addExpDialogVisible = false
    },

    // 添加仪器耗材
    handleAddMaterialBtn(val) {
      this.addDemoOrGroup = val
      this.fetchMaterialListInfo()
      if (val === 1) {
        this.addMaterialList = [...this.demoExpTable]
      } else {
        this.addMaterialList = [...this.groupExpTable]
      }
      this.addMaterialDialogVisible = true
    },
    // 添加耗材
    handleAddMaterialBtnClick() {
      if (!this.placeRoomId) {
        this.$message.warning('请选择存放位置')
        return
      }
      this.fetchMaterialListInfo()
      // 保存数据
      this.tempAddMaterialList = []
      for (let i = 0; i < this.addMaterialList.length; i++) {
        let obj = {}
        for (const key in this.addMaterialList[i]) {
          obj[key] = this.addMaterialList[i][key]
        }
        this.tempAddMaterialList.push(obj)
      }
      this.addMaterialDialogVisible = true
    },
    // 删除所有已添加耗材
    handleRemoveAllAddedMaterialBtnClick() {
      this.addMaterialList = [];
      this.materialTableDataList.forEach((it) => {
        it.isSelected = false;
        this.$refs.materialTableDataList.toggleRowSelection(it, false);
      });
    },
    // 删除已添加耗材按钮
    handleRemoveSelectMaterialBtn(item) {
      this.addMaterialList.forEach((it, i) => {
        if (item.materialId == it.materialId) {
          this.addMaterialList.splice(i, 1);
        }
      });
      this.$nextTick(() => {
        this.materialTableDataList.forEach(it=>{
          if(it.materialId == item.materialId){
            this.$refs.materialTableDataList.toggleRowSelection(it, false);
            it.isSelected = false
          }
        })
      });
    },
    
    handleSelectOneMaterial(selection, row) {
      this.materialRowClick(row);
    },
    handleSelectAllMaterial(selection) {
      if (selection.length > 0) {
        selection.forEach((it) => {
          let result = true;
          this.addMaterialList.forEach((it2) => {
            if (it.materialId == it2.materialId) {
              result = false;
            }
          });
          if (result) {
            it.isSelected = true;
            this.addMaterialList.push(it);
          }
        });
      } else {
        this.materialTableDataList.forEach((it) => {
          this.addMaterialList.forEach((it2) => {
            if (it.materialId == it2.materialId) {
              this.$nextTick(() => {
                this.handleRemoveSelectMaterialBtn(it2);
              });
            }
          });
        });
      }
    },
    materialRowClick(row, column, event) {
      if (row.isSelected) {
        this.handleRemoveSelectMaterialBtn(row);
      } else {
        this.$refs.materialTableDataList.toggleRowSelection(row, true);
        row.isSelected = true;
        this.addMaterialList.push(row);
      }
    },
    // 修改仪器耗材分页页容量
    handleSizeChangeMaterialTableDataMsgPage(val) {
      this.materialTableDataMsgPage.pageIndex = 1
      this.materialTableDataMsgPage.pageSize = val
      this.fetchMaterialListInfo()
    },
    // 修改仪器耗材分页当前页
    handleCurrentChangeMaterialTableDataMsgPage(val) {
      this.materialTableDataMsgPage.pageIndex = val
      this.fetchMaterialListInfo()
    },
    // 取消添加仪器耗材
    handleCancelAddMaterialBtnClick() {
      this.addMaterialList = this.tempAddMaterialList
      this.addMaterialDialogVisible = false
    },
    // 保存添加仪器耗材
    handleSaveAddMaterialBtnClick() {
      this.isSavingMaterialList = true
      // 填充表格
      this.addedMaterialDataList = [
        ...this.addMaterialList.map((item) => ({
          ...item,
          stockNum: 10,
        })),
      ]
      // console.log(this.addedMaterialDataList);
      if (this.addDemoOrGroup === 1) {
        // 添加演示
        this.demoExpTable = [...this.addedMaterialDataList]
      } else {
        // 添加分组
        this.groupExpTable = [...this.addedMaterialDataList]
      }
      this.addMaterialDialogVisible = false
    },
    // 调用该方法可以判断仪器耗材表格一栏是显示 添加 还是 已添加
    handleMaterialStatusCheck(row) {
      let result = false
      if (this.addMaterialList && this.addMaterialList.length > 0) {
        this.addMaterialList.forEach((item) => {
          if (item.internationCode === row.internationCode) {
            result = true
          }
        })
      }
      return result
    },
    // 表格内添加仪器耗材按钮
    handleTableAddMaterialBtnClick(row) {
      this.addMaterialList.push(row)
    },
    // 重置耗材表
    handleResetMaterialTableDataBtnClick() {
      this.materialCodeName = ''
      this.materialTableDataMsgPage.pageIndex = 1
      this.materialTableDataMsgPage.pageSize = 5
      this.fetchMaterialListInfo()
    },
    // 查询耗材表
    handleQueryMaterialTableDataBtnClick() {
      this.materialTableDataMsgPage.pageIndex = 1
      this.materialTableDataMsgPage.pageSize = 5
      this.fetchMaterialListInfo()
    },
    // 表格选择事件
    handleSelectionChange(selection) {
      // console.log(selection)
      this.materialSelectionList = selection
    },
    // 批量添加耗材事件
    handleBatchAddBtnClick() {
      if (this.materialSelectionList.length === 0) return
      for (let i = 0; i < this.materialSelectionList.length; i++) {
        let result = true
        for (let j = 0; j < this.addMaterialList.length; j++) {
          if (
            this.materialSelectionList[i].internationCode ===
            this.addMaterialList[j].internationCode
          ) {
            result = false
          }
        }
        if (result) {
          this.addMaterialList.push(this.materialSelectionList[i])
        }
      }
      // 清空已选的选框
      this.$refs.materialTableDataList.clearSelection()
    },

    // 移除已添加的耗材
    handleDeleteGroupItem(i) {
      this.groupExpTable.splice(i, 1)
    },
    handleDeleteDemoItem(i) {
      this.demoExpTable.splice(i, 1)
    },

    // 课程查询按钮
    inquireBtn() {
      this.page.pageIndex = 1
      this.page.pageSize = 10
      this.fetchCourseDataList()
    },
    resetBtn() {
      this.conditionForm = {
        nameOrCode: '',
        sysPeriodDicId: '',
        sysSubjectDicId: '',
        sysSchoolClassGradeId: '',
        enable: '',
      }
      this.inquireBtn()
    },
    // 分页事件
    handleSizeChange(val) {
      this.page.pageIndex = 1
      this.page.pageSize = val
      this.fetchCourseDataList()
    },
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.fetchCourseDataList()
    },

    // 学段选择清空年级
    handleChangePeriod(e) {
      this.courseForm.sysSchoolClassGradeId = ''
    },
    // 学段下拉框选择
    inquirePeriodBtn(val) {
      this.allGradeList = []
      this.conditionForm.sysSchoolClassGradeId = ''
      this.periodList.forEach((it) => {
        if (it.value === val) {
          this.allGradeList = it.children
        }
      })
      this.inquireBtn()
    },
    // 科目选择移除已选的实验
    handleSubjectSelect() {
      this.courseForm.sysExperimentName = ''
    },
    findExpNameFunc(id) {
      if (this.expList.length > 0) {
        return this.expList.find((it) => it.experimentId === id).experimentName
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.selectText {
  position: absolute;
  right: 230px;
  top: 10px;
  height: 18px;
  line-height: 18px;
  font-size: 16px;
  color: #00f;
  text-decoration: underline;
  cursor: pointer;
}

.tag-title {
  padding-left: 10px;
  border-left: 1px solid #ccc;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  margin-bottom: 25px;
}
.tag-content {
  margin-bottom: 25px;
  width: 95%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 15px 10px 15px;
}
.tag-content-item {
  padding: 0 10px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  position: relative;
  display: inline-block;
  margin-right: 40px;
  margin-top: 10px;
}
.tag-item-x {
  position: absolute;
  right: -22px;
  top: -1px;
  height: 35px;
  width: 20px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}
</style>

import { request, noTimeOutReq } from "@/utils/request.js";

//根据科目展示所有【物品目录表信息】
export function getMaterialMenuInfoApi(data) {
  return request({
    url: `/sys/experiment/category/list`,
    method: "post",
    data,
  });
}

// 新增【物品目录数据】
export function addMaterialMenuApi(data) {
  return request({
    url: `/sys/experiment/category/add`,
    method: "post",
    data,
  });
}

// 删除【物品目录数据】
export function deleteMaterialMenuApi(categoryId) {
  return request({
    url: `/sys/experiment/category/delete`,
    method: "post",
    params: {
      categoryId,
    },
  });
}

// 获取【物品目录数据】详情
export function getMaterialMenuDetailApi(categoryId) {
  return request({
    url: `/sys/experiment/category/get`,
    method: "post",
    params: {
      categoryId,
    },
  });
}

// 修改【物品目录数据】
export function eidtMaterialMenuApi(data) {
  return request({
    url: `/sys/experiment/category/update`,
    method: "post",
    data,
  });
}

// 分页展示所有【实验信息表信息】
export function getLabInfoFormMsgApi(pageIndex, pageSize, data) {
  return request({
    url: `/sys/experiment/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 新增【实验信息数据】
export function addLabInfoFormMsgApi(data) {
  return request({
    url: `/sys/experiment/add`,
    method: "post",
    data,
  });
}

// 删除【实验信息数据】
export function deleteLabInfoFormMsgApi(experimentId) {
  return request({
    url: `/sys/experiment/delete`,
    method: "post",
    params: {
      experimentId,
    },
  });
}

// 获取【实验信息数据】详情
export function getLabInfoDetailApi(experimentId) {
  return request({
    url: `/sys/experiment/get`,
    method: "post",
    params: {
      experimentId,
    },
  });
}

// 修改【实验信息数据】
export function eidtLabInfoFormMsgApi(data) {
  return request({
    url: `/sys/experiment/update`,
    method: "post",
    data
  });
}
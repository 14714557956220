import { request, noTimeOutReq } from "@/utils/request.js";

// 添加实验课程
export function addExpCourseApi(data) {
  return request({
    url: `/crs/exp/course/add`,
    method: "post",
    data,
  });
}

// 删除实验课程
export function delExpCourseApi(crsExpCourseId) {
  return request({
    url: `/crs/exp/course/delete/${crsExpCourseId}`,
    method: "post",
  });
}

// 根据学科分页查询实验
export function getSubjectExpListApi(
  pageIndex,
  pageSize,
  sysSubjectDicId,
  data
) {
  return request({
    url: `/crs/exp/course/page/sysExperiment/${sysSubjectDicId}/${pageIndex}/${pageSize}`,
    method: "post",
    data,
  });
}

// 分页查询仪器耗材标准
export function getMaterialStandardListApi(pageIndex, pageSize, data) {
  return request({
    url: `/crs/exp/course/page/sysExperimentConsumeMaterial/${pageIndex}/${pageSize}`,
    method: "post",
    data,
  });
}

// 分页条件查询实验课程
export function getExpCourseListApi(pageIndex, pageSize, data) {
  return request({
    url: `/crs/exp/course/page/${pageIndex}/${pageSize}`,
    method: "post",
    data,
  });
}

// 更新实验教程
export function updateExpCourseApi(crsExpCourseId, data) {
  return request({
    url: `/crs/exp/course/update/${crsExpCourseId}`,
    method: "post",
    data,
  });
}
